import React from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { graphql, navigate } from "gatsby";

import BlogLayout from "../components/BlogLayout";
import BlogCard from "../components/BlogCard";
import Pagination from "../components/Pagination";
import SEO from "../components/SEO";

import colors from "../utils/theme/colors";

const BlogCategoryTemplate = ({
  pageContext: { numPages, currentPage },
  data: {
    categoryPageInfo: {
      fields: { slug },
      frontmatter: { seoTitle, description, keywords, title, longTitle }
    },
    blogs: { edges: blogs },
    allBlogs: { edges: allBlogs },
    site: {
      siteMetadata: { siteUrl, titleTemplateBlog, socialLinks }
    },
    bgMobile: {
      childImageSharp: { fluid: bgMobileFluid }
    }
  }
}) => {
  return (
    <>
      <SEO
        titleTemplate={titleTemplateBlog}
        title={seoTitle}
        keywords={keywords}
        url={`${siteUrl}${slug}`}
        description={description}
      />
      <BlogLayout
        title="blog"
        socialTitle={title}
        allBlogs={allBlogs}
        socialLinks={socialLinks}
        slug={`${siteUrl}${slug}`}
        bgMobileFluid={bgMobileFluid}
        longTitle={longTitle}
      >
        <Flex
          width="100%"
          flexDirection="column"
          ml={[0, 0, "44px", "-44px"]}
          mr={[0, 2, "85px", "85px"]}
        >
          {blogs.map(data => (
            <BlogCard
              key={data.node.id}
              mb={8}
              data={data}
              css={{ backgroundColor: colors.white }}
            />
          ))}
          <Pagination
            page={currentPage}
            nextButtonClick={() =>
              navigate(
                currentPage === 2 ? slug : `${slug}/page=${currentPage - 1}`
                // eslint-disable-next-line prettier/prettier
              )}
            previousButtonClick={() =>
              // eslint-disable-next-line prettier/prettier
              navigate(`${slug}/page=${currentPage + 1}`)}
            countPage={numPages}
          />
        </Flex>
      </BlogLayout>
    </>
  );
};

BlogCategoryTemplate.propTypes = {
  pageContext: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.shape().isRequired
};

export default BlogCategoryTemplate;

export const query = graphql`
  query blogCategory($categoryTitle: String, $skip: Int!, $limit: Int!) {
    bgMobile: file(relativePath: { eq: "images/bg-mobile.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    blogs: allMarkdownRemark(
      limit: $limit
      skip: $skip
      filter: {
        frontmatter: {
          collection_name: { eq: "blog-posts" }
          category: { eq: $categoryTitle }
        }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          fileAbsolutePath
          fields {
            slug
          }
          frontmatter {
            category
            postImage {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            date
            title
            introtext
            author
          }
        }
      }
    }
    allBlogs: allMarkdownRemark(
      filter: { frontmatter: { collection_name: { eq: "blog-posts" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            category
          }
        }
      }
    }
    categoryPageInfo: markdownRemark(
      frontmatter: {
        collection_name: { eq: "blog-categories" }
        title: { eq: $categoryTitle }
      }
    ) {
      fields {
        slug
      }
      frontmatter {
        title
        seoTitle
        description
        keywords
        longTitle
      }
    }
    site {
      siteMetadata {
        siteUrl
        socialLinks {
          icon
          url
        }
      }
    }
  }
`;
